<template>
    <b-card-actions title="路灯列表" @refresh="refreshStop()" ref="refreshCard">
        <b-row>
            <b-col lg="5" class="mb-1">
                <b-button-group>
                    <b-button variant="primary" class="btn-icon" v-b-toggle.sidebar-1 @click="clearfrom()"><feather-icon
                            icon="PlusCircleIcon" /></b-button>
                    <!-- <b-button variant="primary" class="btn-icon" @click="selectAllRows()"><feather-icon
                            icon="CheckCircleIcon" />&nbsp;<span class="align-middle">{{ select_mode }}</span></b-button> -->
                    <!-- <b-button variant="primary" class="btn-icon" @click="regular()"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-center><feather-icon
                            icon="WatchIcon" />&nbsp;<span class="align-middle">定时</span></b-button> -->
                </b-button-group>
            </b-col>
            <!-- <b-col lg="6" class="mb-1">
                <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text><feather-icon icon="SearchIcon" /></b-input-group-prepend>
                    <b-form-input placeholder="名称" v-model="search_name" />
                    <b-input-group-append><b-button variant="outline-primary"
                            @click="get_search()">检索</b-button></b-input-group-append>
                </b-input-group>
            </b-col> -->
        </b-row>
        <b-row>
            <b-col lg="12">
                <b-table ref="selectableTable" @row-selected="onRowSelected" selectable select-mode="multi"
                    class="tabledata" striped hover responsive="sm" :items="items" :fields="fields" sticky-header
                    no-border-collapse>
                    <template #cell()="data">
                        <span class="text-nowrap">
                            {{ data.value }}
                        </span>
                    </template>
                    <!-- 名字 -->

                    <!-- <template #cell(online_status)="data">
                        <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.online_status)}`"
                            class="text-capitalize">
                            <span v-if="data.item.online_status == 1">在线</span>
                            <span v-else>离线</span>
                        </b-badge>
                    </template> -->

                    <!-- 定时状态 -->
                    <template #cell(open_status)="data">
                        <b-form-checkbox :checked="data.item.open_status == '1' ? 'true' : 'false'" aria-colindex="12"
                            @change='changeHand(data)' name="check-button" switch inline>
                        </b-form-checkbox>
                    </template>

                    <!-- 状态 -->
                    <!-- <template #cell(open_status)="data">
                        <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.open_status)}`"
                            class="text-capitalize">
                            <span v-if="data.item.open_status == 1">打开</span>
                            <span v-else>关闭</span>
                        </b-badge>
                    </template> -->
                    <!-- 设置 -->
                    <template #cell(actions)="data">
                        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                            </template>


                            <b-dropdown-item @click="get_edit(data.item)" v-b-toggle.sidebar-1>
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">编辑</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="get_del(data.item.id)">
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50">删除</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number last-number
                    prev-class="prev-item" next-class="next-item" class="mb-0">
                    <template #prev-text> <feather-icon icon="ChevronLeftIcon" size="18" /></template>
                    <template #next-text> <feather-icon icon="ChevronRightIcon" size="18" /></template>
                </b-pagination>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12">
                <b-sidebar id="sidebar-1" shadow bg-variant="white" title="" right backdrop width="auto">
                    <addition :editData="editData" @get_data="get_data" ref="mod"></addition>
                </b-sidebar>
            </b-col>
        </b-row>
        <!-- 弹窗 -->
        <b-modal id="modal-center" centered title="定时开启" ok-only ok-title="确定" @ok="labelOk" width="1000">
            <div>
                <b-form>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="开始日期" label-for="h-start_time" label-cols-md="4">

                                <b-form-datepicker id="datepicker-invalid" placeholder="开始日期" class="mb-2" />
                            </b-form-group>

                        </b-col>

                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="结束日期" label-for="h-end_time" label-cols-md="4">

                                <b-form-datepicker id="example-datepicker" placeholder="结束日期" class="mb-2" />

                            </b-form-group>
                        </b-col>
                    </b-row>

                </b-form>

            </div>
        </b-modal>

    </b-card-actions>
</template>
  
<script>
import {
    BDropdown, BDropdownItem,
    BLink,
    BBadge, BAvatar, BMedia, BCardHeader, BCardBody, BCard, BCardText, BPagination, BButton,
    BPopover, BTable, BFormGroup, BFormSelect, BFormInput, BInputGroupAppend, BButtonGroup, BRow, BCol,
    BInputGroup, BInputGroupPrepend, BSidebar, VBToggle, BFormDatepicker, BFormTimepicker,
    BForm,BFormCheckbox
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import addition from '@/views/data/addition/add.vue'
// import Details from '@/views/system/addition/details.vue'

import { avatarText } from '@core/utils/filter'
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BLink,
        BBadge,
        BMedia,
        addition,
        BCardHeader,
        BCardBody,
        BTable,
        BCard,
        BCardText,
        BCardActions,
        BButton,
        BPopover,
        BPagination,
        BFormGroup,
        BFormSelect,
        BInputGroupAppend,
        BButtonGroup,
        BRow,
        BCol,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BSidebar,
        VBToggle,
        vSelect,
        ToastificationContent,
        BAvatar,
        avatarText,
        BFormDatepicker,
        BFormTimepicker,
        BForm,
        BFormCheckbox

    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            // Filter
            avatarText,
            fields: [
                { key: 'id', label: 'ID', },
                { key: 'name', label: '名称', },
                { key: 'bh', label: '编号', },
                { key: 'location_x', label: '经度', },
                { key: 'location_y', label: '纬度', },
                { key: 'gateway', label: '网关', },
                { key: 'open_status', label: '状态', },
                { key: 'actions', label: '设置', },
            ],
            items: [],
            table_height: 400,
            currentPage: 1,
            perPage: 50,
            rows: 0,
            search_ld: "",
            search_zz: "",
            search_name: "",
            search_bh: '',
            selected: [],
            select_all: 0,
            select_mode: "全选",
            roleFilter: '',
            planFilter: '',
            statusFilter: '',
            roleOptions: [],
            planOptions: [{ label: '冻结', value: '0' },
            { label: '正常', value: '1' },],
            editData: {
                act: '',
                id: "",
                bh: '',
                location_x: '',
                name: '',
                location_y: '',
                open_status: '',
                gate_id: "",

            },
            timeValue: ''

        }
    },
    created() {
        this.get_data()
    },
    watch: {
        currentPage: function (oldV, newV) {
            this.get_data()
        },
    },
    methods: {
        clearfrom() {
            this.$refs.mod.clearfrom()
        },
        changeHand(e) {
            console.log(e, '1123233');
            // if (e.item.status == '0') {
            //     this.status_id = 1
            // } else {
            //     this.status_id = 0
            // }
            let data = e.item
            this.get_change(data)
        },
        get_change(val) {
            let dataObj = {
                act: "mod",
                lamp_id: val.bh,
                gate_id: val.gateway
            }
            this.$http.post('/svr/yunhu_task.php', dataObj)
                .then(res => {
                    if (res.code == 20000) {
                        this.showToast('success', '状态设置成功')
                        // this.get_data()
                    }
                })
        },
        //定时
        regular() {

        },
        labelOk() {

        },

        getRole(val) {
            console.log(val, '11');
            this.planFilter = val
        },
        getStause(val) {
            console.log(val, '22');
            this.planFilter = val
        },

        onRowSelected(items) {
            this.selected = items
            if (items.length > 0) {
                this.showToast('success', '选中了' + (items.length) + '条数据!')
            }
        },
        selectAllRows() {
            if (this.select_all == 1) {
                this.clearSelected()
                return
            }
            this.$refs.selectableTable.selectAllRows()
            this.select_all = 1
            this.select_mode = "取消全选"
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
            this.select_all = 0
            this.select_mode = "全选"
        },
        refreshStop() {
            this.currentPage = 0
            this.search_name = ""
            this.get_data()
        },

        get_data() {
            this.$nextTick(() => {
                this.$refs["refreshCard"].showLoading = true
            })
            let dataObj = {
                act: "lst",
                pge: this.currentPage,
                cnt: this.perPage,
            }
            this.$http.post('/svr/lamp_device.php', dataObj)
                .then(res => {
                    this.items = res.data.lst
                    console.log(res.data.lst, '列表数据');

                    // this.rows = res.data.cnt_all
                    setTimeout(() => {
                        this.$refs["refreshCard"].showLoading = false
                    }, 300)
                })
        },
        del_data(ids) {
            let dataObj = {
                act: "del",
                id: ids,
            }
            this.$http.post('/svr/lamp_device.php', dataObj)
                .then(res => {
                    this.showToast('success', '删除成功')
                    this.get_data()
                })
        },
        deleteAllRows(id) {

            if (!this.selected.length > 0 && !id > 0) {
                this.showToast('danger', '请先选择您要删除的数据!')
                return
            }
            this.$swal({
                title: '确定删除数据吗?',
                text: "此操作将不可逆,请谨慎操作",
                showCancelButton: true,
                cancelButtonText: '取消',
                confirmButtonText: '我确定!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.del_data(id)
                }
            })
        },
        showToast(variant, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: '系统提示',
                    icon: 'BellIcon',
                    text: text,
                    variant,
                },
            })
        },
        set_delids(id) {
            let val = this.selected
            let ids = []
            val.forEach(item => {
                ids.push(item.id)
            })
            ids = ids.join();
            if (id) {
                ids = id;
            }
            return ids;
        },

        get_del(val) {
            this.deleteAllRows(val)
        },
        get_edit(e) {
            this.editData = e
        },
        // getinfor(data) {
        //   this.editData = data
        // },
        resolveUserRoleVariant(role_id) {
            if (role_id === '管理员') return 'success'
            if (role_id === '用户') return 'warning'
            return 'success'
        },
        resolveUserRoleIcon(role_id) {
            if (role_id === '管理员') return 'UserIcon'
            if (role_id === '用户') return 'AwardIcon'
            return 'UserIcon'
        },
        resolveUserStatusVariant(status) {
            if (status === '0') return 'warning'
            if (status === '1') return 'success'
            return 'primary'
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.tabledata {
    border-radius: 3px;
}

.b-table-sticky-header {
    max-height: calc(100vh - 400px) !important;
}

.custom-select {
    padding: 0.438rem 0rem 0.438rem 0.6rem;
    width: 3.4rem;
}

.b-table-selectable {
    .feather {
        font-size: 1.3rem;
    }
}

.filter {
    margin-bottom: 2rem;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.1rem;
    font-weight: 600;
    /* padding: 10px 0; */
}

.card {
    margin-bottom: 0 !important;
}
</style>
  