<template>
    <section id="card-actions">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col cols="12">
                    <b-card>

                        <!-- 头像名称 -->
                        <!-- <b-media class="mb-2">
                            <template #aside>
                                <b-avatar :src="userData.avatar" :text="avatarText(userData.name)" size="60px" rounded />
                            </template>
                            <h4 class="mb-1">
                                {{ userData.fullName }}
                            </h4>
                            <div class="d-flex flex-wrap mt-2">
                                <b-button variant="primary" @click="$refs.refInputEl.click()">
                                    <input ref="refInputEl" type="file" class="d-none" @change="upload_img">
                                    <span class="d-none d-sm-inline">上传</span>
                                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                                </b-button>
                                <b-button variant="outline-secondary" class="ml-1" small @click="chane_tx">
                                    <span class="d-none d-sm-inline">移除</span>
                                    <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
                                </b-button>
                            </div>
                        </b-media> -->
                        <!-- 个人信息 -->
                        <b-form>
                            <b-row>
                                <!-- Field: Username -->
                                <b-col cols="12" md="12">
                                    <b-form-group label="路灯名称" label-for="username">
                                        <validation-provider #default="{ errors }" name="name" rules="required">
                                            <b-form-input id="username" placeholder="请输入名称" v-model="userData.name"
                                                :state="errors.length > 0 ? false : null" />
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                            </b-row>
                            <!-- <b-row>
                                <b-col cols="12" md="12">
                                    <b-form-group label="IP地址" label-for="ip">
                                        <validation-provider name="ip" rules="required" #default="{ errors }">
                                            <b-form-input id="ip" v-model="userData.ip"
                                                :state="errors.length > 0 ? false : null" placeholder="请输入ip" />
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row> -->
                            <b-row>
                                <b-col cols="12" md="12">
                                    <b-form-group label="经度" label-for="location_x">
                                        <validation-provider name="location_x" rules="required" #default="{ errors }">
                                            <b-form-input id="location_x" v-model="userData.location_x"
                                                :state="errors.length > 0 ? false : null" placeholder="请输入经度" />
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" md="12">
                                    <b-form-group label="纬度" label-for="location_y">
                                        <validation-provider name="location_y" rules="required" #default="{ errors }">
                                            <b-form-input id="location_y" v-model="userData.location_y"
                                                :state="errors.length > 0 ? false : null" placeholder="请输入纬度" />
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <!-- <b-row>
                                <b-col cols="12" md="12">
                                    <b-form-group label="亮度" label-for="bright">
                                        <validation-provider #default="{ errors }" name="bright" rules="required">
                                            <b-form-input id="full-bright" placeholder="请输入亮度" v-model="userData.bright"
                                                :state="errors.length > 0 ? false : null" />
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row> -->
                            <b-row>

                                <b-col cols="12" md="12">
                                    <b-form-group label="网关" label-for="gateway">
                                        <validation-provider name="gateway" rules="required" #default="{ errors }">
                                            <v-select v-model="gatewayData" :options="gatewayOptions" label="name"
                                                :reduce="val => val.id" :state="errors.length > 0 ? false : null"
                                                placeholder="请选择网关" />
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" md="12">
                                    <b-form-group label="编号" label-for="email">
                                        <validation-provider #default="{ errors }" name="Email" rules="required|email">
                                            <v-select v-model="status" :options="statusOptions" label="title"
                                                :reduce="val => val.value" :state="errors.length > 0 ? false : null"
                                                placeholder="请选择编号" />
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                        </b-form>

                        <!-- Action Buttons -->
                        <b-button variant="primary" class="mt-1 mb-sm-0 mr-0 mr-sm-1" @click="save()">
                            保存
                        </b-button>



                    </b-card>
                </b-col>
            </b-row>
        </validation-observer>
    </section>
</template>

<script>
import {
    BButton, BMedia, BCardText, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        vSelect,
        BCardActions,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
        VuePerfectScrollbar,
        BListGroup, BListGroupItem,
        BCardText

    },
    data() {
        return {
            count: 0,
            required,
            email,
            avatarText,
            roles_all: [],
            roleOptions: [],


            fields: [
                { key: 'label', label: '主模块', },
                { key: 'children', label: '子模块', },
            ],
            permissionsData: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 350,
                wheelPropagation: false,
            },
            role_id: "1",
            selected: [],
            userData: {
                act: '',
                id: '',
                name: '',
                bh: '',
                location_x: '',
                location_y: '',
                gate_id: '',
            },
            buildData: '',
            buildDataOptions: [],
            pid: '',
            pidOptions: [],
            status: {},
            gatewayData: {},
            gatewayOptions: [],
            statusOptions: [
                { title: 'A01', value: 'A01' },
                { title: 'A02', value: 'A02' },
                { title: 'A03', value: 'A03' },
                { title: 'A04', value: 'A04' },
                { title: 'A05', value: 'A05' },
                { title: 'A06', value: 'A06' },
                { title: 'A07', value: 'A07' },
                { title: 'A08', value: 'A08' },
                { title: 'A09', value: 'A09' },
                { title: 'A10', value: 'A10' },
                { title: 'A11', value: 'A11' },
                { title: 'A12', value: 'A12' },
                { title: 'A13', value: 'A13' },
                { title: 'A14', value: 'A14' },
                { title: 'A15', value: 'A15' },
                { title: 'A16', value: 'A16' },

            ],
        }
    },
    props: ['editData'],
    watch: {
        editData(newVal, oldVal) {
            if (newVal) {
                this.userData = newVal
                console.log(this.userData, '编辑');
                // this.gatewayData = this.userData.gateway
                let dataVal = this.gatewayOptions.filter((item) => item.name == this.userData.gateway)
                dataVal.map(e => {
                    this.gatewayData = e.id
                })
                // debugger
                this.status = this.userData.bh
                this.count = 0
            }
        },
        // buildData: function (oldV, newV) {
        //     console.log(oldV, '选中楼栋id');
        //     this.get_zzSelect(oldV)

        // }

    },
    created() {
        this.get_way()
    },
    methods: {
        get_way() {
            let dataObj = {
                act: "lst",
                pge: 0,
                cnt: 100,
            }

            this.$http.post('/svr/lamp_gateway.php', dataObj)
                .then(res => {
                    this.gatewayOptions = res.data.lst
                })
        },

        //获取住宅


        clearfrom() {
            this.userData = {
                act: '',
                id: '',
                name: '',
                bh: '',
                location_x: '',
                location_y: '',
                gate_id: '',
            }
        },
        chane_tx() {
            this.userData.avatar = "";
        },

        upload_img(event) {
            let that = this
            var file = event.target.files[0]        //获取文件         
            const formData = new FormData();
            formData.append("fle", file);
            axios({
                method: "post",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                url: "http://yunhu.njqianyan.cn/wlwpt/svr/uld.php?act=pic",
                data: formData,
            })
                .then(function (response) {
                    console.log(response.data.img, "返回头像");
                    that.userData.avatar = response.data.img;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        showToast(variant, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: text,
                    icon: 'BellIcon',
                    variant,
                },
            }, { position: 'top-center' })
        },
        save() {

            this.$refs.simpleRules.validate().then(success => {
                console.log(success);
                // if (success) {
                    if (!this.userData.id) {
                        this.userData.act = 'add'
                    } else {
                        this.userData.act = 'mod'
                    }
                    this.editData.id = this.userData.id
                    this.userData.bh = this.status
                    this.userData.gate_id = this.gatewayData

                    this.$http.post('/svr/lamp_device.php', this.userData).then(res => {
                        if (res.code == 20000) {
                            this.showToast('success', '保存成功!')
                            this.$emit('get_data')
                        } else {
                            this.showToast('danger', res.msg)
                        }
                    })
                // }
            })

        },

    },

}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.tabledata {
    border-radius: 3px;
}

.custom-select {
    padding: 0.438rem 0rem 0.438rem 0.6rem;
    width: 3.4rem;
}

.b-table-selectable {
    .feather {
        font-size: 1.3rem;
    }
}

.filter {
    margin-bottom: 2rem;
}

.b-table-sticky-header {
    max-height: calc(100vh - 520px);
}

.card-actions {
    padding: 2rem;
}

.user_roles_mod {
    height: calc(100vh - 460px) !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.custom-control {
    display: inline-block !important;
}

.card {
    margin-bottom: 0 !important;
}
</style>