var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"card-actions"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"路灯名称","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","placeholder":"请输入名称","state":errors.length > 0 ? false : null},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"经度","label-for":"location_x"}},[_c('validation-provider',{attrs:{"name":"location_x","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"location_x","state":errors.length > 0 ? false : null,"placeholder":"请输入经度"},model:{value:(_vm.userData.location_x),callback:function ($$v) {_vm.$set(_vm.userData, "location_x", $$v)},expression:"userData.location_x"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"纬度","label-for":"location_y"}},[_c('validation-provider',{attrs:{"name":"location_y","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"location_y","state":errors.length > 0 ? false : null,"placeholder":"请输入纬度"},model:{value:(_vm.userData.location_y),callback:function ($$v) {_vm.$set(_vm.userData, "location_y", $$v)},expression:"userData.location_y"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"网关","label-for":"gateway"}},[_c('validation-provider',{attrs:{"name":"gateway","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.gatewayOptions,"label":"name","reduce":function (val) { return val.id; },"state":errors.length > 0 ? false : null,"placeholder":"请选择网关"},model:{value:(_vm.gatewayData),callback:function ($$v) {_vm.gatewayData=$$v},expression:"gatewayData"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"编号","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statusOptions,"label":"title","reduce":function (val) { return val.value; },"state":errors.length > 0 ? false : null,"placeholder":"请选择编号"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}])})],1)],1)],1)],1),_c('b-button',{staticClass:"mt-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" 保存 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }